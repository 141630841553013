import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/ap-credits": [~3],
		"/ap-credits/[gift_card_id]": [~4],
		"/check-email": [~5],
		"/forgot-password": [~6],
		"/forgot-password/confirmation": [8],
		"/forgot-password/[slug]": [~7],
		"/jobs": [~9],
		"/jobs/[id]": [~10],
		"/jobs/[id]/[resource]": [~11],
		"/login": [~12],
		"/orders": [~13],
		"/orders/[id]": [~14],
		"/organizations": [~15],
		"/organizations/[org_id]": [~16],
		"/register/[invitationId]": [~17],
		"/subjects": [~18],
		"/subjects/[subject_id]": [~19],
		"/subjects/[subject_id]/[resource]": [~20]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';